<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
       <!-- trade -->
       <div style="display: flex;align-items: center;">
          <v-select style="width: 110px; margin-right: 20px" :items="tradeTypeList" v-model="curTradeType"
            @change="changeTrade" item-text="label" item-value="type" dense dark>
          </v-select>
       </div>

        <div class="line-chat">
          <v-btn-toggle tile background-color="#424d5f" color="accent-3" v-model="curDateType"
            @change="__fetchOHLCV">
            <v-btn x-small style="background:transparent" value="1m">
              <span style="color:#fff" >1m</span>
            </v-btn>
            <v-btn x-small style="background:transparent" value="1h">
              <span style="color:#fff" >1h</span>
            </v-btn>
            <v-btn x-small style="background:transparent" value="1d">
              <span style="color:#fff">1d</span>
            </v-btn>
          </v-btn-toggle>
        </div>
    </div>
    <div v-if="newOHLC.length">
      <span style="margin-right: 8px;font-size: 12px;">{{ `open: ${newOHLC[0]}` }}</span>
      <span style="margin-right: 8px;font-size: 12px;">{{ `close: ${newOHLC[1]}` }}</span>
      <span style="margin-right: 8px;font-size: 12px;">{{ `lowest: ${newOHLC[2]}` }}</span>
      <span style="margin-right: 8px;font-size: 12px;">{{ `highest: ${newOHLC[3]}` }}</span>
    </div>

    <v-progress-linear v-if="isLoadingData" indeterminate color="darkBlue darken-2"></v-progress-linear>

    <div id="trade-price" :style="{width: contentWidth , height: '580px',}">
     </div>
  </div>
</template>
<script>
import axios from '../utils/request.js'
export default {
  name: 'CcxtTrade',
  data () {
    return {
      isLoadingData: false,
      dateType: [],
      newOHLC: [],
      loopOHLCVNew:'',
      curDateType:'1h',

      tradeTypeList: [
        {
          type: 'BTC',
          label:"BTC/USD"
        },{
          type: 'ETH',
          label:"ETH/USD"
        },{
          type: 'UNI',
          label: "UNI/USD"
        } 
      ],
      curTradeType: 'BTC',

    }
  },
  created() {
    this.currenScreenWidth = window.screen.width;
  },
  computed: {
    contentWidth() {
     return this.currenScreenWidth  > 750 ? "750px" : `${this.currenScreenWidth}px`
    }
  },
  mounted () {
    this.__fetchOHLCV()
    this.__fetchOHLCVNew()
    this.loopOHLCVNew = 0
    this.loopOHLCVNew = setInterval(()=>{
      this.__fetchOHLCVNew()
    },20000)
  },
  methods: {
    changeTrade(){
      this.__fetchOHLCV()
    },
    __fetchOHLCV (){ // 获取数据
      this.isLoadingData = true
      axios.get(`/ccxt/fetchOHLCV/${this.curTradeType}/${this.curDateType}`).then((res) => {
        console.log(res,'获取数据--fetchOHLCV')
        this.initOHLCV(res.data)
        this.isLoadingData = false
      }).catch((err) => {
        console.log(err,'err666')
        this.isLoadingData = false
      })
    },

    initOHLCV(rawData) { // k线图
      const chartDom = document.getElementById('trade-price')
      const myChart = this.$echarts.init(chartDom)

      const upColor = '#ec0000';
      const upBorderColor = '#8A0000';
      const downColor = '#00da3c';
      const downBorderColor = '#008F28';

      const dates = rawData.map((item)=>{
        return this.$moment(item[0]).format('YYYY-MM-DD HH:mm');
      });

      // Each item: open，close，lowest，highest
      const data1 = rawData.map(function (item) {
          return [+item[1], +item[4], +item[3], +item[2]];
      });


      function calculateMA(dayCount, data) {
          var result = [];
          for (var i = 0, len = data.length; i < len; i++) {
            if (i < dayCount) {
              result.push('-');
              continue;
            }
            var sum = 0;
            for (var j = 0; j < dayCount; j++) {
              sum += +data[i - j][1];
            }
            result.push((sum / dayCount).toFixed(4));
          }
          return result;
        }
      console.log(dates,data1,'data0666')
      const option = {
        darkMode: true,
        title: {
          // text: `${this.curTradeType}/USD`,
          // left: 10
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          data: ['日K', 'MA5', 'MA10', 'MA20'],
          top:'10px',
          right:'10px',
          textStyle:{
            color:'#fff'
          }
        },
        grid: {
          top: '11%',
          left: this.currenScreenWidth  > 750 ? '8%' :'12%',
          right: '3%',
          bottom: '15%'
        },
        // backgroundColor: '#100C2A',
        xAxis: {
          type: 'category',
          data: dates,
          boundaryGap: false,
          axisLine: { 
            onZero: false,
            lineStyle:{
              color:'#fff'
            }
          },
          splitLine: { show: false },
          min: 'dataMin',
          max: 'dataMax'
        },
        yAxis: {
          scale: true,
          axisLine: { 
            lineStyle:{
              color:'#fff'
            }
          },
          splitLine: { // 分割线
            show: false,
            lineStyle: {
              color: '#fff'
            }
          },
          splitArea: {
            // show: true
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 85,
            end: 100
          },
          {
            show: true,
            type: 'slider',
            top: '90%',
            start: 85,
            end: 100
          }
        ],
        series: [
          {
            name: `${this.curTradeType}/USD`,
            type: 'candlestick',
            data: data1,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor
            }
          },
          {
            name: 'MA5',
            type: 'line',
            data: calculateMA(5, data1),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA10',
            type: 'line',
            data: calculateMA(10, data1),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
          {
            name: 'MA20',
            type: 'line',
            data: calculateMA(20, data1),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      option && myChart.setOption(option)
    },

    __fetchOHLCVNew (){ // 获取最新数据
      axios.get(`/ccxt/fetchOHLCV/${this.curTradeType}/1m`).then((res) => {
        // console.log(res,'获取数据--__fetchOHLCVNew')
          if (res.code===1) {
            const resData = res.data[ res.data.length - 1]
            this.newOHLC = [resData[1],resData[4],resData[3],resData[2]]
          }
        
      }).catch((err) => {
        console.log(err,'err666')
      })
    },
  },
  destroyed() {
    clearInterval(this.loopOHLCVNew)
    this.loopOHLCVNew = 0
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
  color: #c3c5cb;
  display: flex;
  padding-left: 10%;
  margin-bottom: 10px;
  .footer_image {
    display: flex;
    align-items: baseline;
    > div {
      margin-right: 16px;
      img {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &.mobile {
    padding: 0;
    justify-content: center;
  }
}
</style>
  