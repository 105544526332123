<template>
  <div>
    <div :class="type === 'bull' ? 'certificate-bull' : 'certificate-bear'" v-if="proOrLite === 'pro'">
      <div class="tag-ifno">
        <div class="lumps"><span></span><span></span></div>
        <h4>{{ type === 'bull' ? $t('牛证') : $t('熊证') }}</h4>
      </div>
      <div class="select-container">
        <div class="item-select">
          <div class="item-name" :class="proOrLite === 'pro' && isMobileBol ? 'p-l':'' " style="display: flex;justify-content: space-between;">
            {{ $t('标的物') }}
            <span :style="activeColor">|</span>
          </div>
          <v-select :height="35"  :hide-details="true" class="item select-item" :items="tradeTokenList"
            v-model="currentTrade" item-text="name" item-value="address" return-object dark dense>
            <template v-slot:selection="{ item }">
              <span  style="text-align: center; width: 100%;">
                {{ item.name }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="item-select">
          <div class="item-name" :class="proOrLite === 'pro' && isMobileBol ? 'p-l':'' " style="display: flex;justify-content: space-between;">{{ $t('结算币') }}
            <span :style="activeColor">|</span>
          </div>
          <v-select class="item" :height="35" :hide-details="true" :items="settleTokenList" v-model="currentSettle"
            item-text="name" item-value="address" return-object dense dark>
            <template v-slot:selection="{ item }">
              <span  style="text-align: center; width: 100%;">
                {{ item.name }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="item-select">
          <div class="item-name" :class="proOrLite === 'pro' && isMobileBol ? 'p-l':'' " style="display: flex;justify-content: space-between;">
            {{ $t('开仓数量') }}
            <span :style="activeColor" >|</span>
          </div>
          <v-text-field class="item" :height="35" dark hide-details v-model="amountInput"></v-text-field>
        </div>
        <div class="item-select">
          <div class="item-name" :class="proOrLite === 'pro' && isMobileBol ? 'p-l':'' " style="display: flex;justify-content: space-between;">
            {{ $t('钱包余额') }}
            <span :style="activeColor">|</span>
          </div>
          <v-text-field class="item" :height="35" dark readonly hide-details
            :value="String(Balance).replace(/^(.*\..{4}).*$/, '$1')"></v-text-field>
        </div>
      </div>
      <h5>{{$t('杠杆倍数')}}</h5>
      <div class="lever">
        <div v-for="(i, index) in leverageLabels" @click="handleTypeClick({ type: (type === 'bull' ? 0 : 1), index })"
          :key="index" class="item" :class="leveIndex === index ? 'active' : ''">
          {{ i + 'x' }}
        </div>
      </div>
      <div class="pt-2 pb-2 d-flex align-center justify-center">
        <v-btn :loading="isVerifingLoading" v-show="!isVerified" :disabled="!amountInput ||
          amountInput == '0' ||
          isNaN(Number(amountInput)) ||
          Number(amountInput) > Number(Balance)
          " @click="handleVerify" style="width: 46%; margin-right: 20px" :class="type" class="rounded-lg" :outlined="isMobileBol">
          <span style="color:#fff"> {{ $t("批准") }} </span>
        </v-btn>
        <v-btn :loading="isVerifiedLoading" :disabled="!isVerified ||
          !amountInput ||
          amountInput == '0' ||
          isNaN(Number(amountInput)) ||
          Number(amountInput) > Number(Balance)
          " @click="handleTrade" :style="{width: isVerified?'100%':'49%'}" :class="type" class="rounded-lg" :outlined="isMobileBol">
          <span style="color:#fff"> {{ $t("开仓")+ longOrShot   }} </span>
        </v-btn>
      </div>

    </div>

    <div :class="type === 'bull' ? 'certificate-bull' : 'certificate-bear'" v-else>
      <div class="tag-ifno">
        <div class="lumps"><span></span><span></span></div>
        <h4>{{ type === 'bull' ? $t('牛证') : $t('熊证') }}</h4>
      </div>
      <v-select :height="40" :hide-details="true" class="top-select item select-item" :items="tradeTokenList"
        v-model="currentTrade" item-text="name" item-value="address" return-object dark dense>
        <template v-slot:selection="{ item }">
          <span style="text-align: center; width: 100%;">
            {{ item.name }}
          </span>
        </template>
      </v-select>
      <div class="custom-container">
        <div class="left-container">
          <v-select class="item" :height="40" :hide-details="true" :items="settleTokenList" v-model="currentSettle"
            item-text="name" item-value="address" return-object dense dark>
            <template v-slot:selection="{ item }">
              <span style="text-align: center; width: 100%;">
                {{ item.name }}
              </span>
            </template>
          </v-select>
          <v-text-field class="centered-input item" :height="40" dark hide-details single-line v-model="amountInput">
          </v-text-field>
        </div>
        <div class="right-container">
          <div v-for="(i, index) in leverageLabels" @click="handleTypeClick({ type: (type === 'bull' ? 0 : 1), index })"
            :key="index"
            :class="[{ 'odd': index % 2 === 0 }, { 'even': index % 2 !== 0 }, { 'active': leveIndex === index }]">
            {{ i + 'x' }}</div>
        </div>
      </div>
      <div class="pt-2 pb-2 d-flex align-center justify-center">
        <v-btn :loading="isVerifingLoading" v-show="!isVerified" :disabled="!amountInput ||
          amountInput == '0' ||
          isNaN(Number(amountInput)) ||
          Number(amountInput) > Number(Balance)
          " @click="handleVerify" style="width: 46%; margin-right: 20px" :class="type" class="rounded-lg" :outlined="isMobileBol">
          <span style="color:#fff"> {{ $t("批准") }} </span>
        </v-btn>
        <v-btn :loading="isVerifiedLoading" :disabled="!isVerified ||
          !amountInput ||
          amountInput == '0' ||
          isNaN(Number(amountInput)) ||
          Number(amountInput) > Number(Balance)
          " @click="handleTrade" :style="{width: isVerified?'100%':'49%'}" :class="type" class="rounded-lg" :outlined="isMobileBol">
          <span style="color:#fff"> {{ $t("开仓") + longOrShot}}  </span>
        </v-btn>
      </div>
    </div>

        <!-- 交易成功弹窗 -->
    <v-dialog v-model="isShowDialog" overlay-color="#333846" :width="isMobileBol ? '' : '520px'">
      <v-card style="background: #232429">
        <v-container class="text-center font-weight-bold textColor--text text-h6">
          {{ $t("交易成功") }}</v-container>
        <v-container class="pl-5 pr-5 pb-5">
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground"
            @click="handleViewOnEarthscan">
            {{ $t(tradeSuccessTips) }}</v-btn>
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground" @click="handSuccessConfirm">
            {{ $t("确定") }}</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import helper from '../helpers'
import { getNetworkConfig } from '../chainNetConfig'

import axios from '../utils/request.js'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Trade',
  props: {
    type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      currentTradePrice: '-',
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      isShowVerifyDialog: false,
      isShowDialog: false,
      tabs: [{ label: this.$t('牛证') }, { label: this.$t('熊证') }],
      currentIndex: 0,
      tradeTokenList: [],
      settleTokenList: [],
      amountInput: 10, // 输入的开仓数额
      verified: true,
      currentSettle: null, // 当前选择的结算币 类型
      currentTrade: null, // 当前选择的标的物 类型
      leveIndex: 0,
      VerifingLoading: false,
      VerifiedLoading: false,
      Balance: 0,
      allow: 0,
      tradeSuccessTips: '在HECOINFO上查看',
      confirmReceipt: null// 交易完成后的收据Receipt
    }
  },
  computed: {
    activeColor() {
      return this.type === 'bull' ? { color: '#244F94' } : { color: '#65109F' }
    },

    // 做空还是做多
    longOrShot() {
      return this.type === 'bull' ? '('+ this.$t('做多') + ')' : '('+ this.$t('做空') + ')'
    },
    isVerified: function () {
      return this.verified
    },
    isVerifingLoading: function () {
      return this.VerifingLoading
    },
    isVerifiedLoading: function () {
      return this.VerifiedLoading
    },
    leverageLabels: function () { // 杠杆倍数选项，根据网络配置动态获取（不同的结算币，或标的物，牛熊证类型，杠杆选项可能不同）

      if (this.currentSettle && this.currentTrade && this.currentNetworkConfig) {
        const res = []
        console.log(this.currentSettle.name.substring(1, this.currentSettle.name.length))
        const settleName = this.currentSettle.name.startsWith('l')
          ? this.currentSettle.name.substring(1, this.currentSettle.name.length) : this.currentSettle.name
        const type = this.currentIndex ? '0' : '1'
        for (let i = 0; i < this.currentNetworkConfig.cbbcType.length; i++) {
          const item = this.currentNetworkConfig.cbbcType[i]
          if (item.type === type &&
            item.tradeType === this.currentTrade.name &&
            item.settleType === settleName) {
            res.push(item.leverage.toString())
          }
        }
        return res
      } else {
        return []
      }
    },
    ...mapState(['isMobileBol', 'defaultAccount', 'defaultChainId', 'currentNetworkConfig', 'currentEth', 'pendingStatus', 'proOrLite'])
  },
  watch: {
    async currentSettle(val) {
      this.leveIndex = 0
      if (val.name === 'HT' || val.name === 'BNB' || val.name === 'ETH') {
        await helper.getETHBalance(this.defaultAccount, (balance) => {
          this.Balance = String(balance).replace(/^(.*\..{4}).*$/, '$1')
        })
      } else {
        if (val.isLiquidity) { // 流动币
          // 余额是持有的对应的流动币
          this.verified = false
          this.Balance = await helper.getLiquilityBalance(val.name.substring(1, this.currentSettle.name.length), this.defaultAccount)
          // this.amountInput = 0
        } else {
          if (val.name === 'CHARM' || val.name === 'CNYT') {
            this.verified = false
            this.Balance = await helper.getBalance(val.address, this.defaultAccount)
            // this.amountInput = 0
            return
          }
          this.Balance = await helper.getBalance(val.address, this.defaultAccount)
          this.allow = await helper.allowance(val.address, this.defaultAccount)
          // this.amountInput = 0
        }
      }
      this.leveIndex = this.leveIndex < 0 ? 0 : this.leveIndex
    },
    async currentTrade(val) {
      const tradePriceInfo = await helper.getTradePrice()
      const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === val.name })
      this.currentTradePrice = helper.toEth(priceData.tradePrice)
    },
    defaultAccount: function () {
      this.initData()
    },
    async pendingStatus() {//pending状态
      if (!this.pendingStatus) {
        this.Balance = await helper.getBalance(this.currentSettle.address, this.defaultAccount)
      }
    },
  },
  mounted() {
    this.initData()
    this.$nextTick(async () => {
      const config = await getNetworkConfig()
      this.tradeSuccessTips = config.tradeSuccessTips
    })
  },
  methods: {
    ...mapMutations(['upCurrentEth', 'upPendingStatus']),
    async __updataWrrantInfoAndUser(quantity, tradePrice, warrantInfo) { // 开仓成功后，调接口 更新权证记录和用户信息
      let cbbcprice = '1'
      const config = await getNetworkConfig()
      const cbbcType2 = config.cbbcType
      const cbbcTypeList = cbbcType2.filter((i) => { return i.Bytes32 === warrantInfo.address })
      try {
        const cppcInfo = await helper.getCbbcTypeInfo(this.defaultAccount, cbbcTypeList)// 获取当前权证已有的权证价格
        cbbcprice = cppcInfo[0].cbbcprice.toString()
      } catch (error) {
        cbbcprice = '1'
      }
      console.log(warrantInfo, 'warrantInfowarrantInfo')
      console.log(cbbcprice, 'cppcInfo--cbbcprice')
      const params = {
        address: this.defaultAccount,
        chainId: this.defaultChainId,
        quantity,
        cbbcprice,
        tradePrice: helper.toWei(tradePrice),
        warrantInfoId: warrantInfo.Bytes32
      }
      axios.post('/trade', params).then(() => {
        // if (res.code === 1) {
        // } else {
        // }
        this.amountInput = ''
      }).catch((error) => {
        console.log(error, '66')
        this.amountInput = ''
      })
    },
    async initData() {
      const config = await getNetworkConfig()
      this.settleTokenList = await helper.getSettleTokenListByConfig() // 所有的结算币 list
      this.settleTokenList = [...this.settleTokenList, ...config.tradeLiquiditySettleTokenList]// 能开仓的流动币类型

      this.tradeTokenList = await helper.getTradeTokenListByConfig()

      this.currentSettle = this.settleTokenList[0]
      this.currentTrade = this.tradeTokenList[0]
      this.Balance = await helper.getBalance(this.settleTokenList[0].address, this.defaultAccount)
    },
    handleTabChange(index) { // 切换tab
      this.currentIndex = index
    },
    // 杠杆倍数
    handleTypeClick(val) {
      const { type, index } = val
      this.currentIndex = type
      this.leveIndex = index
    },
    async handleViewOnEarthscan() {
      const transactionHash = this.confirmReceipt ? this.confirmReceipt : ''
      const config = await getNetworkConfig()
      const url = `${config.netUrl}/tx/${transactionHash}`
      window.open(url)
    },
    handSuccessConfirm() {
      this.isShowDialog = false
    },
    handleVerify() { // 批准
      if (this.leverageLabels.length === 0) {
        return
      }
      const routeV2Tokens = ["CAKE", "BUSD"]
      this.VerifingLoading = true
      if (this.currentSettle.isLiquidity || this.currentSettle.name === 'CHARM' || this.currentSettle.name === 'CNYT') { // 流动币开仓 批准
        const settleName = this.currentSettle.name.startsWith('l')
          ? this.currentSettle.name.substring(1, this.currentSettle.name.length) : this.currentSettle.name

        helper.getLiquiditySignature(settleName, this.amountInput, this.defaultAccount,
          (error, permitData, deadline) => {
            if (error != null) {
              this.VerifingLoading = false
            } else {
              this.permitData = permitData
              this.deadline = deadline
              this.verified = true
              this.VerifingLoading = false
              console.log('permitData:' + permitData)
            }
          })
      } else if (routeV2Tokens.includes(this.currentSettle.name)) { //zhh 20221
        helper.approveTokenRouteV2(this.currentSettle, 100000000, this.defaultAccount,
          (error) => {
            if (error != null) {
              console.log(error)
              this.VerifingLoading = false
            }
          }, async () => {
            this.verified = true
            this.VerifingLoading = false
            this.allow = await helper.allowance(this.currentSettle.address, this.defaultAccount)
          })
      } else {
        helper.approveToken(this.currentSettle.Bytes32, 100000000, this.defaultAccount,
          (error) => {
            if (error != null) {
              console.log(error)
              this.VerifingLoading = false
            }
          }, async () => {
            this.verified = true
            this.VerifingLoading = false
            this.allow = await helper.allowanceRouteV2(this.currentSettle.address, this.defaultAccount)
          })
      }
    },
    async handleTrade() { // 开仓
      console.log('handleTrade')
      // four routev2 token
      //const routeV2Tokens = ["CAKE", "MATIC", "MDX", "BUSD"]
      const routeV2Tokens = ["CAKE", "BUSD"]
      if (this.leverageLabels.length === 0) {
        return
      }
      this.VerifiedLoading = true
      const tradeBytes32 = this.currentTrade.Bytes32
      this.leveIndex = this.leveIndex === -1 ? 0 : this.leveIndex
      var trickNumber = this.leverageLabels[this.leveIndex]
      const settleName = this.currentSettle.name.startsWith('l')
        ? this.currentSettle.name.substring(1, this.currentSettle.name.length) : this.currentSettle.name
      var name = ''
      if (this.currentIndex === 0) {
        name = trickNumber + 'X ' + this.currentTrade.name + ' ' + settleName
      } else {
        name = trickNumber + 'X Inverse ' + this.currentTrade.name + ' ' + settleName
      }
      const config = await getNetworkConfig()
      const cbbcType2 = config.cbbcType
      const warrantInfo = cbbcType2.find((i) => { return i.name === name })
      // console.log(name, 'name')
      console.log(warrantInfo, 'warrantInfo')
      // console.log(cbbcType2, 'cbbcType2cbbcType2')
      // debugger
      // return
      if (this.currentSettle.name === 'HT' || this.currentSettle.name === 'BNB' || this.currentSettle.name === 'ETH') {
        helper.buyCbbcETH(tradeBytes32, trickNumber, (this.currentIndex === 0) ? 1 : 0, this.amountInput, this.defaultAccount, (error) => {
          if (error != null) {
            this.VerifiedLoading = false
          }
        }, async (receipt, tradePrice) => {
          this.VerifiedLoading = false
          this.isShowDialog = true
          this.confirmReceipt = receipt
          this.upPendingStatus(true)
          await helper.getETHBalance(this.defaultAccount, (balance) => {
            this.Balance = String(balance).replace(/^(.*\..{4}).*$/, '$1')
            this.upCurrentEth(this.Balance)
          })
          // console.log(cbbcAmount)
          this.__updataWrrantInfoAndUser(this.amountInput, tradePrice, warrantInfo)// 开仓成功，更新记录
        })
      } else if (routeV2Tokens.includes(this.currentSettle.name)) { //zhh 20221210 add routev2 tokens
        console.log("routeV2Tokens:" + this.currentSettle.name);
        helper.buyCbbcRouteV2(this.currentSettle.Bytes32, tradeBytes32, trickNumber, (this.currentIndex === 0) ? 1 : 0, this.amountInput, this.defaultAccount, (error, transactionHash) => {
          console.log(error, transactionHash, 'error, transactionHash11')
          if (error != null) {
            this.VerifiedLoading = false
          }
        }, async (receipt, tradePrice) => {
          this.upPendingStatus(true)
          this.VerifiedLoading = false
          this.isShowDialog = true
          this.confirmReceipt = receipt
          // this.Balance = await helper.getBalance(this.currentSettle.address, this.defaultAccount)
          this.__updataWrrantInfoAndUser(this.amountInput, tradePrice, warrantInfo)// 开仓成功，更新记录
        })
      }
      else {
        // todo add cnyt?
        console.log("liquid this.currentSettle.name:" + this.currentSettle.name)
        if (this.currentSettle.isLiquidity || this.currentSettle.name === 'CHARM' || this.currentSettle.name === 'CNYT') { // 流动币开仓
          const settleBytes32 = (this.currentSettle.name === 'CHARM' || this.currentSettle.name === 'CNYT')
            ? this.currentSettle.Bytes32 : this.currentSettle.settleBytes32
          console.log("this.currentSettle.Bytes32:" + this.currentSettle.Bytes32)
          console.log("this.currentSettle.settleBytes32:" + this.currentSettle.settleBytes32)
          console.log("settleBytes32:" + settleBytes32)
          helper.buyCbbcUsingLiquidityTokenWithPermit(settleBytes32, tradeBytes32, trickNumber, (this.currentIndex === 0) ? 1 : 0,
            this.amountInput, this.defaultAccount, this.deadline, this.permitData, (error, transactionHash) => {
              console.log(error, transactionHash, 'error, transactionHash')
              // this.VerifiedLoading = false
              // this.verified = false
              if (error != null) {
                this.VerifiedLoading = false
                this.verified = false
              }
            }, async (receipt, tradePrice) => {
              console.log(receipt, tradePrice, '2000220000')
              this.upPendingStatus(true)
              this.VerifiedLoading = false
              this.verified = false
              this.isShowDialog = true
              this.confirmReceipt = receipt
              this.Balance = await helper.getBalance(this.currentSettle.address, this.defaultAccount)
              this.__updataWrrantInfoAndUser(this.amountInput, tradePrice, warrantInfo)// 开仓成功，更新记录
            })
          return
        }

        helper.buyCbbc(this.currentSettle.Bytes32, tradeBytes32, trickNumber, (this.currentIndex === 0) ? 1 : 0, this.amountInput, this.defaultAccount, (error, transactionHash) => {
          console.log(error, transactionHash, 'error, transactionHash11')
          if (error != null) {
            this.VerifiedLoading = false
          }
        }, async (receipt, tradePrice) => {
          this.upPendingStatus(true)
          this.VerifiedLoading = false
          this.isShowDialog = true
          this.confirmReceipt = receipt
          // this.Balance = await helper.getBalance(this.currentSettle.address, this.defaultAccount)
          this.__updataWrrantInfoAndUser(this.amountInput, tradePrice, warrantInfo)// 开仓成功，更新记录
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.trade-parameter {
  // width: 450px;
  // color: #ffffff;
  // opacity: 0.9;
  // padding: 20px;
  // border-radius: 20px;

  .trade-name {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .certificate-bull {

    .custom-container {
      display: flex;
      margin: 10px 0;

      .left-container {
        flex: .5;

        .v-text-field {
          padding-top: 0;
          background: #193156;
          font-size: 12px;
          font-weight: 600;
        }

        .item {
          border-radius: 5px;
        }

        .item:last-child {
          margin-top: 10px;
        }
      }

      .right-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        div {
          width: 40.5%;
          background: #193156;
          text-align: center;
          height: 40px;
          line-height: 40px;
          border-radius: 5px;
          cursor: pointer;
        }

        div:hover{
          background-color: #313BD8;
        }

        .odd {
          margin: 5px 0 5px 30px;
        }

        .even {
          margin: 5px 0 5px 0;
        }

        .active {
          background-color: #313BD8;
        }
      }
    }

    .tag-ifno {
      display: flex;
      align-items: center;

      .lumps {
        position: relative;
        height: 25px;

        span:first-child {
          border-left: 14px solid #2C39EC;
          max-height: 25px;
          padding: 3px 0;
        }

        span:last-child {
          border-left: 14px solid #FFFFFF;
          position: absolute;
          height: 16px;
          top: 9px;
          left: 8px;
        }
      }

      h4 {
        padding: 0 0 0 30px;
        font-size: 18px;
      }
    }

    .select-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 10px 0;

      .item-select {
        display: flex;
        justify-content: space-around;
        max-width: 200px;
        background-color: #193156;
        border-radius: 8px;
        align-items: center;
        margin: 6px 0;

        .item-name,
        .item {
          flex: 1;
          padding: 0 5px;
          font-size: 12px;
          font-weight: 600;
          width: 110px;

          .v-text-field {
            padding-top: 0;
            input{
              padding-left: 5px;
            }
            .item>.v-text-field>.v-input__control>.v-input__slot:before {
              border-color: #193156 !important;
            }
          }
        }
        .p-l{
          padding: 0 25px;
        }
      }
    }

    .lever {
      display: flex;
      justify-content: space-between;
      margin: 6px 0;
      flex-wrap: wrap;

      .item {
        background-color: #193156;
        min-width: 80px;
        text-align: center;
        min-height: 35px;
        line-height: 35px;
        margin: 3px 0;
        cursor: pointer;
      }

      .item:hover{
        background-color: #313BD8;
      }

      .active {
        background-color: #313BD8;
      }
    }

    .bull {
      background-color: #193156 !important;
      margin: 5px 0;
    }

    .top-select {
      background-color: #193156;
      margin: 10px 0;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
    }
  }

  .certificate-bear {
    margin-top: 30px;

    .tag-ifno {
      display: flex;
      align-items: center;

      .lumps {
        position: relative;
        height: 25px;

        span:first-child {
          border-left: 14px solid #65109F;
          max-height: 25px;
          padding: 3px 0;
        }

        span:last-child {
          border-left: 14px solid #FFFFFF;
          position: absolute;
          height: 16px;
          top: 9px;
          left: 8px;
        }
      }

      h4 {
        padding: 0 0 0 30px;
        font-size: 18px;
      }
    }

    .select-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 10px 0;

      .item-select {
        display: flex;
        justify-content: space-around;
        max-width: 200px;
        background-color: #3D1956;
        border-radius: 8px;
        align-items: center;
        margin: 6px 0;

        .item-name,
        .item {
          flex: 1;
          padding: 0 5px;
          font-size: 12px;
          font-weight: 600;
          width: 110px;

          .v-text-field {
            padding-top: 0;

          }
        }
        .p-l{
          padding: 0 25px;
        }
      }
    }

    .lever {
      display: flex;
      justify-content: space-between;
      margin: 6px 0;
      flex-wrap: wrap;

      .item {
        background-color: #3D1956;
        min-width: 80px;
        text-align: center;
        min-height: 35px;
        line-height: 35px;
        margin: 3px 0;
        cursor: pointer;
      }

      .active {
        background-color: #65109F;
      }

      .item:hover{
        background-color: #65109F;
      }

    }

    .bear {
      background-color: #3D1956 !important;
      margin: 5px 0;
    }

    .custom-container {
      display: flex;
      margin: 10px 0;
      font-size: 12px;
      font-weight: 600;

      .left-container {
        flex: .5;

        .v-text-field {
          padding-top: 0;
          background: #3D1956;
          font-size: 12px;
          font-weight: 600;
        }

        .item {
          border-radius: 5px;
        }

        .item:last-child {
          margin-top: 10px;
        }
      }

      .right-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        div {
          width: 40.5%;
          background: #3D1956;
          text-align: center;
          height: 40px;
          line-height: 40px;
          border-radius: 5px;
        }

        div:hover{
          background-color: #65109F;
        }

        .odd {
          margin: 5px 0 5px 30px;
        }

        .even {
          margin: 5px 0 5px 0;
        }

        .active {
          background-color: #65109F;
        }
      }
    }

    .top-select {
      background-color: #3D1956;
      margin: 10px 0;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.v-input.item>.v-input__control>.v-input__slot:before {
  border: none;
}

.theme--dark.v-text-field>.v-input__control>.v-input__slot:before {
  display: none
}

 ::v-deep .v-text-field input {
      text-align: center
    }

@media only screen and (max-width: 750px) {
  .trade-parameter .certificate-bull .select-container .item-select, .trade-parameter .certificate-bear .select-container .item-select{
    width: 100%;
    max-width: unset;
  }
}
</style>