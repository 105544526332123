<template>
  <!-- 持仓页面 -->
  <div class="pl-3 pr-3 share-cantainer" :class="{ 'mobie': isMobileBol }">
    <!-- 未解锁钱包 -->
    <v-card v-if="!$store.state.isLogin" style="background: #232429; width: 70%; margin: 0 auto">
      <v-container class="mt-0 mb-0 pt-0 pb-0 unlock-wallet-box">
        <v-container class="text-center font-weight-bold btnTextColor--text unlock">
          {{ $t("请先连接您的钱包") }}
        </v-container>
        <v-divider class="light-divider"></v-divider>
        <v-btn @click="handleUnlockWallet" class="mr-1 rounded-lg unlock-button" color="btnColor">
          <span class="btnTextColor--text"> {{ $t("解锁钱包") }}</span>
        </v-btn>
      </v-container>
    </v-card>

    <!-- 已解锁钱包 -->
    <v-container v-else style="" class="mt-0 mb-0 white--text">
      <div class="text-center title_text" :class="{ 'mobie': isMobileBol }">
        <h4>{{ $t("您的持仓") }}</h4>
        <div :class="{ 'mobie': isMobileBol }">
          <v-btn @click="handleRefresh()" :loading="isLoadingData" class="refresh-btn">
            {{ $t("同步数据") }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span style="margin-bottom: 8px" v-bind="attrs" v-on="on"
                  class="mdi mdi-help-circle-outline mdi-light mdi-16px"></span>
              </template>
              <span>
                {{
                    $t("当本地持仓数据与链上数据不一致时，请点击此按钮进行同步")
                }}</span>
            </v-tooltip>
          </v-btn>
        </div>

      </div>

      <v-btn text color="primary" v-if="isMobileBol" @click="isFoldFilter = !isFoldFilter">
        {{ !isFoldFilter ? $t("收起筛选") : $t("开始筛选") }}
      </v-btn>
      <!-- 筛选 -->
      <v-container :style="{ display: isFoldFilter ? 'none' : 'block', width: '100%' }" style="padding-bottom: 0;" fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterSettleList" :label="$t('结算币')" v-model="filterForm.settle" dark clearable
              dense>
            </v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterTradeList" :label="$t('标的资产')" v-model="filterForm.trade" dark clearable
              dense>
            </v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterLeverageList" :label="$t('杠杆倍数')" v-model="filterForm.leverage" dark
              clearable dense></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterList" :label="$t('类型')" v-model="filterForm.type" dark clearable dense>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <!-- 列表2 -->
      <v-container fluid>
          <v-progress-linear v-if="isLoadingData" indeterminate color="darkBlue darken-2"></v-progress-linear>

          <v-simple-table
            class="share-table-list"
            style="background:transparent;color:#fff"
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="background-color: #132848;color:#fff">
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('结算币') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('标的资产') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('杠杆倍数') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('类型') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('开仓成本') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('持仓数额') }}
                  </th>
                  <th style="background-color: #132848;color:#fff">
                    {{ $t('收益率') }}
                  </th>
                  <th style="background-color: #132848;color:#fff;text-align: center;">
                    {{ $t('操作') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in tableData"
                  :key="index"
                >
                  <td><v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${item.settleType}.png`)"
                  max-height="30" max-width="30">
                </v-img></td>
                  <td>{{ item.settleType }}</td>
                  <td>{{ item.tradeType }}</td>
                  <td>{{ item.leverage + "X" }}</td>
                  <td>
                    <div v-if="item.type == '熊证'" class="text-left darkBlue--text">
                      {{ $t(item.type) }}
                    </div>
                    <div v-if="item.type == '牛证'" class="text-left darkPink--text">
                      {{ $t(item.type) }}
                    </div>
                  </td>
                  <td> {{ item.cost }}</td>
                  <td>{{ item.portionPrice }}</td>
                  <td>{{ item.APY2 }}</td>
                  <td style="text-align: center;">
                    <v-btn 
                    style="width:80px;color:#fff;background: #1A67DE;border-radius: 4px;" small 
                    @click="buyShowDialog(item)"> <span>{{ $t("开仓") }}</span>
                    </v-btn>
                    <v-btn 
                    style="width:80px;margin:0 5px;color:#fff;background: #E66161;border-radius: 4px;" small 
                    @click="sellShowDialog(item)"> <span>{{ $t("平仓") }}</span>
                    </v-btn>
                    <v-btn 
                    style="width:80px;color:#fff;background: #1A67DE;border-radius: 4px;" small
                    @click="openDetailShowDialog(item)"> <span>{{ $t("详情")
                    }}</span> </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-container>
    </v-container>

    <!-- 开仓弹窗 -->
    <v-dialog v-model="isBuyDialog" overlay-color="#202e8d" :width="isMobileBol ? '' : '520px'">
      <v-card style="background: #202e8d">
        <v-container class="text-center font-weight-bold white--text text-h6">
          <span> {{ $t("开仓") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isBuyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-5 pr-5 pb-5">
          <v-container class="mb-5" dark style="color:#fff ">
            <p class="mb-0 text-body-2 settle-type-radio-group" v-if="buySettleType !== 'CHARM' && buySettleType !== 'CNYT'&& buySettleType !== 'CAKE' && buySettleType !== 'BUSD'">
              <span class="label"> {{ $t("结算币") }}： </span>
              <v-radio-group @change="buySettleTypeChange" v-model="buySettleType" row>
                <v-radio v-for="(item, index) in openSettleTypeList" :key="index" :value="item.name">
                  <template v-slot:label>
                    <span style="color: #c3c5cb">{{ item.name }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </p>

            <p class="mb-0 text-body-2">
              {{ $t("开仓") + "(" + $t("余额") + ")：" + maxOpenTrade }}
            </p>
            <div class="d-flex align-center justify-space-between" style="height: 44px">
              <v-text-field class="pt-0" v-model="buyNum" dark></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#4A2F4E" @click="buyNum = maxOpenTrade"><span class="darkPink--text">
                    {{ $t("最大") }}</span></v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :loading="buyApproveLoading" :disabled="
            !buyNum ||
            buyNum == '0' ||
            isNaN(Number(buyNum)) ||
            Number(buyNum) > Number(maxOpenTrade)
          " v-show="!isBuyApprove" @click="buyApprove" class="rounded-lg mb-1" color="#0483FF"><span
              class="white--text"> {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="openTreadBtnLoading" :disabled="
            !isBuyApprove || !buyNum || buyNum == '0' || isNaN(Number(buyNum))
          " @click="buyConfirm" class="rounded-lg" color="#424D5F"><span class="bearButtonTrade--text">
              {{ $t("开仓") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 平仓弹窗 -->
    <v-dialog v-model="isSellDialog" overlay-color="#202e8d" :width="isMobileBol ? '' : '520px'">
      <v-card style="background: #202e8d">
        <v-container class="text-center font-weight-bold white--text text-h6">
          <span> {{ $t("平仓") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isSellDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-container class="pl-5 pr-5 pb-5">
          <v-container class="mb-5" style="color:#fff">
            <p class="mb-0 text-body-2">{{ $t("平仓比例") }}</p>
            <div class="d-flex align-center justify-space-between pt-9" style="height: 44px">
              <v-subheader class="pl-0 pr-0 white--text">0</v-subheader>
              <v-slider max="100" min="0" v-model="sellPercent" :thumb-size="18" thumb-label="always"
                track-color="#C3C5CB" color="#e359e1" thumb-color="#e359e1"></v-slider>
              <v-subheader class="pl-0 pr-0 white--text">100</v-subheader>
            </div>
          </v-container>

          <v-container class="mb-5" style="color:#fff">
            <p class="mb-0 text-body-2">
              {{ $t("平仓数量") }}({{ maxSell }})
            </p>
            <div class="d-flex align-center justify-space-between" style="height: 44px">
              <v-text-field dark class="pt-0 white--text" v-model="sellNum"></v-text-field>
              <v-subheader class="pl-0 pr-0" style="padding-bottom: 23px">
                <v-btn color="#4A2F4E" :disabled="loadingMax" @click="sellPercent = 100"><span class="darkPink--text">
                    {{ $t("最大") }}</span></v-btn>
              </v-subheader>
            </div>
          </v-container>
          <v-btn block :loading="sellSignLoading" v-show="!isSellApprove" :disabled="
            !sellNum ||
            sellNum == '0' ||
            isNaN(Number(sellNum)) ||
            sellNum > maxSell
          " @click="sellApprove" class="rounded-lg mb-1" color="#4A2F4E"><span class="darkPink--text">
              {{ $t("批准") }}</span></v-btn>
          <v-btn block :loading="sellConfirmLoading" :disabled="!isSellApprove" @click="sellConfirm" class="rounded-lg"
            color="#43454A !important"><span class="white--text"> {{ $t("确定") }}</span></v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 详情弹窗 -->
    <v-dialog v-model="isShowDetailDialog" overlay-color="#202e8d" :width="isMobileBol ? '' : '720px'">
      <v-card style="background: #202e8d" max-width="720">
        <v-container class="text-center font-weight-bold  text-h6" style="color:#fff">
          <span> {{ $t("详情") }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isShowDetailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>

        <v-container class="pl-5 pr-5 pb-5">
          <v-progress-linear :active="detailLoading" bottom :indeterminate="detailLoading"></v-progress-linear>
          <div class="detail-list">
            <h3 style="color: #fff; text-align: center; margin-bottom: 15px">
              {{ currentItem && currentItem.breed }}
            </h3>
            <div class="detail-item" v-for="(obj, index) in detailList" :key="index"
              :style="{ width: isMobileBol ? '100%' : '50%' }">
              <span :class="{ en: currentLang === 'en' }">{{ obj.label }}：</span>
              <span :class="{ en: currentLang === 'en' }">{{ obj.value }}</span>
            </div>
          </div>
        </v-container>
        <div style="display: flex; justify-content: center">
          <v-btn @click="handleRebase()" :loading="isRebaseLoading" class="mb-5 text-center rebase-btn" style="box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
              width: 100px;
              height: 35px;
              line-height: 30px;
              background: #3d5fe1;
              color: #fff;
            ">
            rebase
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span style="margin-bottom: 8px" v-bind="attrs" v-on="on"
                  class="mdi mdi-help-circle-outline mdi-light mdi-16px"></span>
              </template>
              <span> {{ $t("权证价格重置为初始值1") }} </span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- 交易成功弹窗 -->
    <v-dialog v-model="isTradeSuccessDialog" overlay-color="#333846" :width="isMobileBol ? '' : '520px'">
      <v-card style="background: #232429">
        <v-container class="text-center font-weight-bold textColor--text text-h6">{{ $t("交易成功") }}</v-container>
        <v-container class="pl-5 pr-5 pb-5">
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground"
            @click="handleViewOnEarthscan">
            {{ $t(tradeSuccessTips) }}</v-btn>
          <v-btn width="100%" class="rounded-lg mb-3" large dark color="darkBlueBackground" @click="handSuccessConfirm">
            {{ $t("确定") }}</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import helper from '../helpers'
import { mapMutations, mapState } from 'vuex'
import axios from '../utils/request.js'
import eventBus from '../utils/eventBus'
import { getNetworkConfig } from '../chainNetConfig.js'

export default {
  name: 'Share2',
  components: {
  },
  data() {
    return {
      loopInterval: '',
      recent: [
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Jason Oner'
        },
        {
          active: true,
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Mike Carlson'
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Cindy Baker'
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Ali Connors'
        }
      ],
      previous: [{
        title: 'Travis Howard',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg'
      }],
      cbbcType: [],
      buySettleType: '', // 开仓结算币 类型
      isRebaseLoading: false, // rebase loading
      isFoldFilter: false, // 移动端 折叠筛选项
      filterForm: { // 筛选form
        type: '', // 类型
        settle: '', // 币种
        trade: '', // 标的
        leverage: '' // 杠杆
      },
      currentLang: 'zhHans',
      filterList: [this.$t('牛证'), this.$t('熊证')], // 类型LIST
      filterSettleList: [], // 币种LIST
      filterTradeList: [], // 标的LIST
      filterLeverageList: ['1X', '2X', '3X', '5X', '10X', '20X',
      '50X','100X','150X','200X','250X',], // 杠杆list

      currentItem: null,
      isBuyDialog: false, // 开仓弹窗
      buyNum: '', // 开仓数量
      maxOpenTrade: 0, // 最大数量（余额）
      buyApproveLoading: false, // 开仓批准loading
      openTreadBtnLoading: false, // 开仓按钮loading

      isTradeSuccessDialog: false, // 交易成功弹窗
      isSellDialog: false, // 平仓弹窗
      sellNum: '', // 平仓数量
      tableData: [], // 列表数据

      sourceData: [],
      maxSell: 0, // 最大平仓数
      sellSignLoading: false, // 平仓 批准按钮 loading
      sellConfirmLoading: false, // 平仓 确定按钮 loading
      isSellApprove: false, // 平仓 是否已批准
      isBuyApprove: false, // 开仓 是否已批准APPROVE
      sellPercent: 0, // 平仓比例
      signature: {},
      deadline: 0,
      loadingMax: false,
      isShowDetailDialog: false,
      detailLoading: false,
      currentNetworkConfig: null, // 当前配置
      tradeSuccessTips: '在HECOINFO上查看', // 交易成功后的提示语
      detailList: [ // 详情
        {
          label: this.$t('权证数量'),
          key: 'amount',
          value: ''

        }, {
          label: this.$t('权证价格'),
          key: 'cbbcprice',
          value: ''
        }, {
          label: this.$t('开仓成本'),
          key: 'cost',
          value: ''
        }, {
          label: this.$t('持仓数额'),
          key: 'portionPrice',
          value: ''
        }, {
          label: this.$t('收益率'),
          key: 'APY2',
          value: ''
        }, {
          label: 'APY',
          key: 'APY',
          value: ''
        }, {
          label: this.$t('标的现价'),
          key: 'btcPrice',
          value: ''
        }, {
          label: this.$t('开仓均价'),
          key: 'averagePrice',
          value: ''
        }, {
          label: this.$t('rebase价格'),
          key: 'rebasePrice',
          value: ''
        }, {
          label: this.$t('开仓时间'),
          key: 'createTime',
          value: ''
        }],
      isLoadingData: false,
      confirmReceipt: null// 平仓-交易完成后的收据Receipt
    }
  },
  watch: {
    defaultAccount() {
      this.__getList()
    },
    defaultChainId() {
      this.__getList()
    },
    sellPercent(val) { // 平仓比例
      const temp = (val / 100) * Number(this.maxSell)
      this.sellNum = temp
    },
    isSellDialog() {
      this.sellNum = ''
      this.sellPercent = 0
    },
    isBuyDialog() { // 开仓弹窗
      this.buyNum = ''
      this.isBuyApprove = false
    },
    isShowDetailDialog(value) {
      this.isRebaseLoading = false
      if (!value) {
        for (let i = 0; i < this.detailList.length; i++) {
          this.detailList[i].value = ''
        }
      }
    },
    filterForm: {
      handler() {
        const list = this.sourceData.filter((item) => {
          const filterType = this.getFilterType(this.filterForm.type)
          const a = !filterType || (filterType && filterType === item.type)
          const b = !this.filterForm.settle || (item.settleType === this.filterForm.settle)
          const c = !this.filterForm.trade || (item.tradeType === this.filterForm.trade)
          const d = !this.filterForm.leverage || (item.breed.includes(this.filterForm.leverage))
          return a && b && c && d
        })
        this.tableData = list
      },
      deep: true

    },
    sourceData: {
      handler() {
        const list = this.sourceData.filter((item) => {
          const filterType = this.getFilterType(this.filterForm.type)
          const a = !filterType || (filterType && filterType === item.type)
          const b = !this.filterForm.settle || (item.settleType === this.filterForm.settle)
          const c = !this.filterForm.trade || (item.tradeType === this.filterForm.trade)
          const d = !this.filterForm.leverage || (item.breed.includes(this.filterForm.leverage))
          return a && b && c && d
        })
        this.tableData = list
      },
      deep: true
    },
    isMobileBol() {
      if (this.isMobileBol) {
        this.isFoldFilter = true
      } else {
        this.isFoldFilter = false
      }
    }
  },
  computed: {
    openSettleTypeList() { // 开仓时选择的结算币类型。除结算币charm外,其余币种均有对应的流动币
      if (!this.currentItem ||
      this.currentItem.settleType === 'CHARM'
      || this.currentItem.settleType === 'CNYT'  || this.currentItem.settleType === 'BUSD'  || this.currentItem.settleType === 'CAKE'
      || !this.currentNetworkConfig) {
        return []
      } else {
        const currentSettle = this.currentNetworkConfig.settleTokenList.find((i) => { return i.address === this.currentItem.settleToken })
        const LiquiditySettle = this.currentNetworkConfig.tradeLiquiditySettleTokenList.find((i) => { return i.address === currentSettle.liquidityTokenAddr })
        return [currentSettle, LiquiditySettle]
      }
    },
    ...mapState(['isMobileBol', 'defaultAccount', 'defaultChainId', 'isLogin'])
  },
  async created() {
    this.currentLang = localStorage.getItem('language')
    const config = await getNetworkConfig()
    this.cbbcType = config.cbbcType
    this.currentNetworkConfig = config
    this.tradeSuccessTips = config.tradeSuccessTips
  },
  mounted() {
    this.isFoldFilter = this.isMobileBol
    this.handleRefresh()
    this.getFilterData()
  },
  methods: {
    ...mapMutations(['upDateDefaultAccount', 'upDateDefaultChainId',
      'upCurrentEth', 'upPendingStatus']),
    getFilterType(type) { // 兼容英文 下的搜索 待优化
      if (this.currentLang === 'en') {
        if (!type) {
          return ''
        }
        return type === 'Bull' ? '牛证' : '熊证'
      } else {
        return type
      }
    },
    async buySettleTypeChange() { // 开仓币种类--改变
      this.buyNum = ''
      this.isBuyApprove = false
      if (this.buySettleType === 'HT' || this.buySettleType === 'BNB' || this.buySettleType === 'ETH') {
        await helper.getETHBalance(this.defaultAccount, (balance) => {
          this.maxOpenTrade = String(balance).replace(/^(.*\..{4}).*$/, '$1')
        })
      } else {
        if (this.buySettleType.startsWith('l')) { // 流动币
          // 余额(最大值)是持有的对应的流动币
          this.maxOpenTrade = await helper.getLiquilityBalance(this.buySettleType.substring(1, this.buySettleType.length), this.defaultAccount)
        } else {
          this.maxOpenTrade = await helper.getBalance(this.currentItem.settleToken, this.defaultAccount)
        }
      }
    },
    // 点击开仓
    async buyShowDialog(item) {
      this.currentItem = item// 当前点击项
      this.buySettleType = item.settleType
      this.isBuyDialog = true
      if (item.settleType === 'HT' || item.settleType === 'BNB' || item.settleType === 'ETH') {
        await helper.getETHBalance(this.defaultAccount, (balance) => {
          this.maxOpenTrade = String(balance).replace(/^(.*\..{4}).*$/, '$1')
        })
      } else {
        const settleToken = await helper.getSettleTokenListByConfig()
        const aa = settleToken.find((i) => { return this.currentItem.settleType === i.name })
        console.log(aa, 'aa')
        this.maxOpenTrade = await helper.getBalance(aa.address, this.defaultAccount)
      }
    },
    async buyApprove() { // 开仓-签名
      this.buyApproveLoading = true
      if (this.buySettleType.startsWith('l') || this.buySettleType === 'CHARM' || this.buySettleType === 'CNYT') { // zhh 流动币开仓 批准
        const settleName = this.buySettleType.startsWith('l') ? this.buySettleType.substring(1, this.buySettleType.length) : this.buySettleType

        helper.getLiquiditySignature(settleName, this.buyNum, this.defaultAccount,
          (error, permitData, deadline) => {
            if (error != null) {
              this.buyApproveLoading = false
            } else {
              this.permitData = permitData
              this.deadline = deadline
              this.isBuyApprove = true
              this.buyApproveLoading = false
            }
          })
      } else { // 非流动币类型的开仓 approve
        const config = await getNetworkConfig()
        const settleBytes32 = config.settleTokenList.find((i) => { return i.address === this.currentItem.settleToken }).Bytes32
        helper.approveToken(settleBytes32, this.maxOpenTrade, this.defaultAccount,
          (error) => {
            if (error != null) {
              this.buyApproveLoading = false
            }
          }, () => {
            // console.log(receipt, 'receiptreceipt')
            this.isBuyApprove = true
            this.buyApproveLoading = false
          })
      }
    },
    async buyConfirm() { // 开仓
      this.openTreadBtnLoading = true
      const tradeType = this.currentItem.tradeType
      const leverage = this.currentItem.leverage
      const openType = this.currentItem.type === '牛证' ? 1 : 0
      const account = this.defaultAccount
      const routeV2Tokens = ["CAKE", "BUSD"]
      const config = await getNetworkConfig()
      const tradeBytes32 = config.tradeTokenList.find((i) => { return i.name === tradeType }).Bytes32

      if (this.buySettleType === 'HT' || this.buySettleType === 'BNB' || this.buySettleType === 'ETH') {
        helper.buyCbbcETH(tradeBytes32, leverage, openType, this.buyNum, account, (error, transactionHash) => {
          console.log(error, transactionHash, 'error, transactionHash22')
          if (error != null) {
            this.buyNum = ''
            this.openTreadBtnLoading = false
          }
        }, async (receipt) => {
          this.upPendingStatus(true)
          this.openTreadBtnLoading = false
          this.isBuyDialog = false
          this.confirmReceipt = receipt
          this.isTradeSuccessDialog = true
          this.handleRefresh()

          await helper.getETHBalance(this.defaultAccount, (balance) => {
            const eth = String(balance).replace(/^(.*\..{4}).*$/, '$1')
            this.upCurrentEth(eth)
          })
        })
      } else {
        const currentSettleBytes32 = config.settleTokenList.find((i) => { return i.address === this.currentItem.settleToken }).Bytes32// 结算币的address

        if (this.buySettleType.startsWith('l') || this.buySettleType === 'CHARM' || this.buySettleType === 'CNYT') { // 流动币开仓
          helper.buyCbbcUsingLiquidityTokenWithPermit(currentSettleBytes32, tradeBytes32, leverage, openType, this.buyNum, account,
            this.deadline, this.permitData, (error, transactionHash) => {
              console.log(error, transactionHash, 'error, transactionHash')
              if (error != null) {
                this.buyNum = ''
                this.openTreadBtnLoading = false
              }
            }, (receipt) => {
              this.upPendingStatus(true)
              this.isBuyApprove = false
              this.openTreadBtnLoading = false
              this.isBuyDialog = false
              this.confirmReceipt = receipt
              this.isTradeSuccessDialog = true
              this.handleRefresh()
            })

        } else if (routeV2Tokens.includes(this.buySettleType)) {
          // zhh 20221219
          // if cake and dusd
          helper.buyCbbcRouteV2(currentSettleBytes32, tradeBytes32, leverage, openType, this.buyNum, account, (error, transactionHash) => {
            console.log(error, transactionHash, 'error, transactionHash')
            if (error != null) {
              this.buyNum = ''
              this.openTreadBtnLoading = false
            }
          }, async (receipt) => {
            this.upPendingStatus(true)
            this.isBuyApprove = false
            this.openTreadBtnLoading = false
            this.isBuyDialog = false
            this.confirmReceipt = receipt
            this.isTradeSuccessDialog = true
            this.handleRefresh()
          })
        }else{
        // 非流动性
        helper.buyCbbc(currentSettleBytes32, tradeBytes32, leverage, openType, this.buyNum, account, (error, transactionHash) => {
          console.log(error, transactionHash, 'error, transactionHash')
          if (error != null) {
            this.buyNum = ''
            this.openTreadBtnLoading = false
          }
        }, async (receipt) => {
          this.upPendingStatus(true)
          this.isBuyApprove = false
          this.openTreadBtnLoading = false
          this.isBuyDialog = false
          this.confirmReceipt = receipt
          this.isTradeSuccessDialog = true
          this.handleRefresh()
        })
        }
      }
    },
    // 点击平仓
    async sellShowDialog(item) {
      console.log('sellShowDialog:'+item)
      this.currentItem = item// 当前点击项
      this.isSellDialog = true
      this.loadingMax = true
      const aa = await helper.getCbbcTokenSupply(this.currentItem.warrantInfoAddress, this.defaultAccount)
      this.maxSell = aa || 0
      this.loadingMax = false
    },
    sellApprove() { /// 平仓-签名
      this.sellSignLoading = true
      helper.sellSignature(this.currentItem.settleType, this.currentItem.warrantInfoAddress, this.sellNum, this.defaultAccount,
        (error, permitData, deadline) => {
          if (error != null) {
            console.log(error)
            this.sellSignLoading = false
          } else {
            this.signature = permitData
            this.deadline = deadline
            this.isSellApprove = true
            this.sellSignLoading = false
          }
        })
    },
    sellConfirm() { /// 平仓
      this.sellConfirmLoading = true
      helper.sellCbbcWithPermit(this.currentItem.warrantInfoAddress, this.currentItem.tradeType, this.sellNum, this.defaultAccount, this.deadline, this.signature, (error, transactionHash) => {
        console.log(error, transactionHash)
        if (error != null) {
          this.isTradeSuccessDialog = false
          this.sellConfirmLoading = false
          this.isSellApprove = false
          this.isSellDialog = false
        }
      }, (receipt) => {
        this.upPendingStatus(true)
        this.isTradeSuccessDialog = true
        this.sellConfirmLoading = false
        this.isSellApprove = false
        this.sellNum = ''
        this.isSellDialog = false
        this.handleRefresh()
        this.confirmReceipt = receipt
      })
    },

    async handleRefresh() { // 同步持仓数据
      console.log('获取列表获取列表')
      if (!this.defaultAccount || !this.defaultChainId) {
        return
      }
      clearInterval(this.loopInterval)
      this.loopInterval = 0
      this.sourceData = []
      this.isLoadingData = true

      const list = await helper.getCbbcTypeInfo(this.defaultAccount)// 获取用户链上的持仓信息
      const paramsList = [] // 获取 批量更新参数
      for (let i = 0; i < list.length; i++) {
        const value = list[i]
        if (value.amount > 0.0001) {
          paramsList.push({
            amount: value.amount,
            account: this.defaultAccount,
            chainId: this.defaultChainId,
            warrantInfoAddress: value.Bytes32,
            warrantPrice: value.cbbcprice,
            tradePrice: value.tradePrice
          })
        }
      }
      console.log(paramsList, 'paramsListparamsList')
      // 请求refresh 接口
      axios.post('/share/refresh', { paramsList }).then((res) => {
        if (res.code === 1) {
          this.__getList()
        }
        this.isLoadingData = false
      }).catch((err) => {
        console.error(err)
        this.isLoadingData = false
      })
    },
    async __getList() { // 获取列表
      if (!this.defaultAccount || !this.defaultChainId) {
        return
      }

      this.isLoadingData = true
      let resData = await helper.getCbbcTypeInfo(this.defaultAccount)// 获取用户链上的持仓信息
      resData = resData.filter((i) => Number(i.amount) > 0.0001)// 只处理数量大于0.0001的数据

      const listResouce = await this.__getListShare() //从数据库获取持仓信息
      console.log(resData, listResouce, '__getList2')

      this.sourceData = await Promise.all(resData.map(async (item) => { //构造列表数据结构

        //zhh 20230107
        let factor = 1.0
        const routeV2Tokens = ["CAKE", "BUSD"]
        const tokenCharmPool = {"CAKE":"0x14d07d4c228574af156aea3e69d57c8aa26f58cc", "BUSD":"0x3e5d2befdbfc4f0345c896916dc637956f7c87e3"}
        if( routeV2Tokens.includes(item.settleType) ) {
          //get settleToken from swap to adjust cost and amount
          let tokenBalance = await helper.getBalanceOfPowerSwap(item.settleType, tokenCharmPool[item.settleType])
          let charmBalance= await helper.getBalanceOfPowerSwap("CHARM", tokenCharmPool[item.settleType])
          if (charmBalance > 0.0001) {
            factor = tokenBalance/charmBalance
          }
          console.log("zhh adjust factor of "+item.settleType+
          ",tokenBalance:"+tokenBalance+
          ",charmBalance:"+charmBalance+
          ",factor:"+factor)
        }
        //zhh end

        // 获取收益率 START
        let APY2 = (((item.amount * item.cbbcprice / item.purchaseCost) - 1) * 100).toFixed(4) + '%'
        if (APY2 === 'Infinity%' || APY2 === '-Infinity%') {
          APY2 = '0%'
        }
        // 获取收益率 END

        const curType = listResouce.find((i) => { return i.warrant_info_id === item.Bytes32 })
        const firstTradeTime = curType && curType.time ? curType.time : new Date()// 首次开仓时间

        const type = item.type === '0' ? '熊证' : '牛证'
        const createTime = this.$moment(firstTradeTime).format('YYYY-MM-DD HH:mm:ss');

        // 获取APY START
        const a = new Date().getTime()// 当前日期时间戳
        const b = new Date(firstTradeTime).getTime()// 首次开仓时间戳
        const c = (item.amount * item.cbbcprice / item.purchaseCost) - 1

        let APY = (c * 86400 * 365 * 1000) / (a - b)
        APY = (APY * 100).toFixed(4) + '%'
        if (APY === 'Infinity%' || APY === '-Infinity%') {
          APY = '0%'
        }
        // 获取APY END
        console.log('item:'+item)
        console.log('item.name:'+item.name)
        return {
          type,
          APY, // APY
          APY2, // 收益率
          breed: item.name, // 权证name
          Bytes32: item.Bytes32,
          settleType: item.settleType,
          settleToken: item.settleToken,
          tradeType: item.tradeType,
          leverage: item.leverage, // 杠杆倍数
          warrantInfoAddress: item.address,
          portionPrice: (item.amount * item.cbbcprice * factor).toFixed(4), // 持仓数额
          cost: (item.purchaseCost * factor).toFixed(4),
          createTime//开仓时间
        }
      })
      )
      this.sortDesserts()// 排序和过滤
      this.isLoadingData = false

      this.loopUpdate()
    },
    __getListShare() { // 从数据库获取持仓信息--仅仅是为了拿到某种权证的首次开仓时间
      return new Promise((resolve, reject) => {
        axios.get(`/share/${this.defaultAccount}/${this.defaultChainId}`)
          .then(function (res) {
            if (res.code === 1) {
              resolve(res.data)
            } else {
              resolve([])
            }
          }).catch(() => {
            reject([])
          })
      })
    },
    async sortDesserts() { // 排序
      const config = await getNetworkConfig()
      const cbbcTypeAll = config.cbbcType
      let res = []
      for (let i = 0; i < cbbcTypeAll.length; i++) {
        const element = cbbcTypeAll[i]
        for (let j = 0; j < this.sourceData.length; j++) {
          if (element.Bytes32 === this.sourceData[j].Bytes32) {
            res.push(this.sourceData[j])
            break
          }
        }
      }

      // 持仓数额小于0.01或收益率为-99%以下的仓位，隐藏持仓
      res = res.filter((item) => {
        return item.APY2.split('%')[0] > -99 && item.portionPrice >= 0.01
      })

      this.sourceData = [...res]
    },
    async getAveragePrice(item) { // 获取开仓均价和 rebasePrice
      // 开仓均价：
      // 牛证： （开仓成本/权证数量+杠杆倍数-1）*rebase价格/杠杠倍数
      // 熊证： （杠杆倍数+1- 开仓成本/权证数量）*rebase价格/杠杠倍数
      const type = item.type
      const cost = Number(item.cost)
      const amount = Number(item.amount)
      const leverage = Number(item.leverage)
      const a = type === 0 || type === '0' || type === '熊证' ? leverage + 1 - (cost / amount) : (cost / amount) + leverage - 1
      const rebasePrice = await helper.getRebasePrice(item.warrantInfoAddress)
      return {
        averagePrice: helper.to4DecimalString(a * Number(rebasePrice.value) / leverage),
        rebasePrice: rebasePrice.value
      }
    },
    async openDetailShowDialog(item) { // 打开 获取详情
      console.log(item, 'item66')
      this.isShowDetailDialog = true
      this.detailLoading = true
      this.currentItem = item// 当前点击项
      const currentItem = item
      const cppcType = this.cbbcType.filter((i) => { return i.address === currentItem.warrantInfoAddress })
      const cppcInfo = await helper.getCbbcTypeInfo(this.defaultAccount, cppcType)// 获取当前权证信息
      console.log(cppcInfo, 'cppcInfo')
      currentItem.amount = cppcInfo[0].amount
      currentItem.cbbcprice = cppcInfo[0].cbbcprice
      currentItem.portionPrice = (currentItem.amount * currentItem.cbbcprice).toFixed(4)

      const a = await this.getAveragePrice(currentItem)
      currentItem.averagePrice = a.averagePrice
      currentItem.rebasePrice = a.rebasePrice

      const tradePriceInfo = await helper.getTradePrice() // tradePrice 标的物价格
      const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === currentItem.tradeType })
      if (tradePriceInfo.code === 0) {
        currentItem.btcPrice = helper.toEth(priceData.tradePrice)
      } else {
        currentItem.btcPrice = '0'
      }

      console.log(currentItem, 'currentItem')

      this.detailList.map((item) => {
        item.value = currentItem[item.key]
      })
      this.detailLoading = false
    },
    handleRebase() { // 操作rebase
      this.isRebaseLoading = true
      helper.rebase(this.currentItem.Bytes32, this.currentItem.tradeType, this.defaultAccount,
        () => {
          // this.isLoadingData = true
          this.isRebaseLoading = false
        }, () => {
          this.upPendingStatus(true)
          this.isShowDetailDialog = false
          // this.isRebaseLoading = false
          // this.isLoadingData = false
        })
    },
    // 交易成功
    async handleViewOnEarthscan() {
      const transactionHash = this.confirmReceipt ? this.confirmReceipt : ''
      const config = await getNetworkConfig()
      const url = `${config.netUrl}/tx/${transactionHash}`
      window.open(url)
    },
    handSuccessConfirm() {
      this.isTradeSuccessDialog = false
    },
    handleUnlockWallet() { // 解锁钱包
      eventBus.$emit('handleUnlockWallet')
    },
    async getFilterData() { // 获取筛选项list
      console.log('getFilterData')
      const settleToken = await helper.getSettleTokenListByConfig()
      const tradeToken = await helper.getTradeTokenListByConfig()
      for (let i = 0; i < settleToken.length; i++) {
        console.log('settleToken[i]:'+settleToken[i])
        this.filterSettleList.push(settleToken[i].name)
      }
      for (let i = 0; i < tradeToken.length; i++) {
        console.log('tradeToken[i]:'+tradeToken[i])
        this.filterTradeList.push(tradeToken[i].name)
      }
    },
    loopUpdate() { // 循环实时更新
      clearInterval(this.loopInterval)
      this.loopInterval = 0
      this.loopInterval = setInterval(async () => {
        console.log('loopUpdatestart---->>>')
        if (!this.defaultAccount || !this.defaultChainId) {
          return
        }

        try {
          let startTime = new Date().getTime()
          const list = await helper.getCbbcTypeInfo(this.defaultAccount)// 获取用户链上的持仓信息
          const paramsList = [] // 获取 批量更新参数
          for (let i = 0; i < list.length; i++) {
            const item = list[i]

            if (item.amount > 0.0001) {

              //zhh 20230107
              let factor = 1.0
              const routeV2Tokens = ["CAKE", "BUSD"]
              const tokenCharmPool = {"CAKE":"0x14d07d4c228574af156aea3e69d57c8aa26f58cc", "BUSD":"0x3e5d2befdbfc4f0345c896916dc637956f7c87e3"}
              if( routeV2Tokens.includes(item.settleType) ) {
                //get settleToken from swap to adjust cost and amount
                let tokenBalance = await helper.getBalanceOfPowerSwap(item.settleType, tokenCharmPool[item.settleType])
                let charmBalance= await helper.getBalanceOfPowerSwap("CHARM", tokenCharmPool[item.settleType])
                if (charmBalance > 0.0001) {
                  factor = tokenBalance/charmBalance
                }
                console.log("zhh adjust factor of "+item.settleType+
                ",tokenBalance:"+tokenBalance+
                ",charmBalance:"+charmBalance+
                ",factor:"+factor)
              }
              //zhh end
              
              let APY2 = (((item.amount * item.cbbcprice / item.purchaseCost) - 1) * 100).toFixed(4) + '%'
              if (APY2 === 'Infinity%' || APY2 === '-Infinity%') {
                APY2 = '0%'
              }

              paramsList.push({
                cost: (item.purchaseCost * factor).toFixed(4),//开仓成本
                amount: item.amount,
                Bytes32: item.Bytes32,
                portionPrice: (item.amount * item.cbbcprice * factor).toFixed(4), // 持仓数额
                APY2: APY2 // 收益率
              })
            }
          }
          for (let i = 0; i < paramsList.length; i++) {
            const element = paramsList[i]

            for (let j = 0; j < this.sourceData.length; j++) {
              if (this.sourceData[j].Bytes32 === element.Bytes32) {
                this.sourceData[j].APY2 = element.APY2
                this.sourceData[j].portionPrice = element.portionPrice
                this.sourceData[j].cost = element.cost
              }
            }
            for (let m = 0; m < this.tableData.length; m++) {
              if (this.tableData[m].Bytes32 === element.Bytes32) {
                this.tableData[m].APY2 = element.APY2
                this.tableData[m].portionPrice = element.portionPrice
                this.tableData[m].cost = element.cost
              }
            }
          }
          let endTime = new Date().getTime()

          console.log((endTime - startTime) / 1000, '获取权证详细信息---》总耗时')
        } catch (error) {
          console.log(error, '循环实时更新--error')
        }
      }, 8000)
    }
  },
  destroyed() {
    clearInterval(this.loopInterval)
    this.loopInterval = 0
  }
}
</script>
<style lang="scss" scoped>
.share-cantainer {
  // width: 84%;
  min-height: 730px;
  overflow: auto;
  margin: 0 auto;
  margin-top: 50px;

  &.mobie {
    width: 100%;
    padding: 0 !important;
    margin-top: 20px;
  }

  .unlock-wallet-box {
    min-height: calc(100vh - 300px);
    position: relative;

    >button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .card_content {
    margin: 30px 15px;
    background-image: url("../assets/card-bg.png");

    .label-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
      font-weight: 400;
      text-align: left;
      color: #283f91;
    }

    .value-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #283f91;
    }

    .icon_image_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -30px;
      width: 100%;
    }
  }

  .card_footer {
    display: flex;
    justify-content: space-around;
    padding: 0 0;

    .text1 {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #1e1e5d;
    }

    .text2 {
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #d2505d;
    }
  }
}

.title_text {
  position: relative;
  color: #fff;
  font-size: 180% !important;
  margin-bottom: 20px;

  &.mobie {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  >div {
    position: absolute;
    right: 2px;
    top: 0;

    &.mobie {
      margin: 8px auto;
      position: initial;
    }

    .refresh-btn {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      min-width: 70px;
      height: 30px;
      line-height: 30px;
      background: #424d5f;
      color: #6da8ff;
    }
  }
}

.light-divider {
  color: #707070;
  border-color: #707070 !important;
}

.unlock {
  padding-top: 20px !important;
}

.unlock-button {
  margin-top: 20px;
}

.detail-list {
  min-height: 220px;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #d1d1d1;
  // background: rgb(41, 41, 41);
  border-radius: 5px;
  padding: 25px 5px;

  .detail-item {
    display: flex;
    color: #ffffff;
    height: 50px;
    float: left;

    >span {
      display: block;

      &:nth-child(1) {
        width: 100px;
        text-align: right;
      }

      &.en:nth-child(1) {
        width: 220px;
        text-align: left;
      }

      &:nth-child(2) {
        // flex: 1;
        color: #c3c5cb;
      }

      &.en:nth-child(2) {
        width: 140px;
        text-align: left;
      }
    }
  }
}
</style>

<style lang="scss">
.settle-type-radio-group {
  display: flex;

  >span.label {
    margin-top: 2px;
  }

  .v-input--radio-group--row {
    margin: 0;
    padding: 0;
  }

  .theme--light.v-icon {
    color: #707070;
  }
}
 .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgb(19, 40, 72)
}
</style>
