<template>
  <div class="trade-contains">
    <div class="trade-quotes-content">
      <!-- 左侧侧表单 -->
      <div class="trade-parameter">
          <h3 class="trade-name">{{ $t('牛熊证') }}</h3>
          <CattleOrBear type="bull"></CattleOrBear>
          <CattleOrBear type="bear"></CattleOrBear>
      </div>
      <!-- 右侧k线 -->
      <div class="k-line-content">
        <CcxtTradePro v-if="proOrLite==='pro'"></CcxtTradePro>
        <CcxtTradeLite v-else></CcxtTradeLite>
      </div>
    </div>
    <!-- 持仓 -->
    <div class="share-content">
      <Share></Share>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CattleOrBear from '../components/CattleOrBear.vue'
import Share from './Share'
import CcxtTradePro from '../components/CcxtTradePro.vue'
import CcxtTradeLite from '../components/CcxtTradeLite.vue'

import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Trade',
  data() {
    return {
    }
  },
  components: {
    Share,
    CattleOrBear,
    CcxtTradePro,
    CcxtTradeLite
  },
  computed: {
    ...mapState(['isMobileBol', 'defaultAccount', 'pendingStatus', 'proOrLite'])
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    ...mapMutations(['upCurrentEth']),
  }
}
</script>
<style lang="scss" scoped>
.trade-contains {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: space-around;
  // margin: 0 auto;
  margin: 40px auto;

  .trade-quotes-content {
    display: flex;
    .trade-parameter {
      width: 450px;
      padding: 20px;
      margin-right: 20px;
      color: #ffffff;
      border-radius: 18px;
      border: 1px solid #214888;
      .trade-name {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }
    }
    .k-line-content {
      width: 780px;
      height: 670px;
      padding: 20px;
      color: #ffffff;
      border-radius: 18px;
      border: 1px solid #214888;
    }
  }
  .share-content {
    width: 64%;
  }
}

@media only screen and (max-width: 750px) {
  .trade-contains{
    margin: auto;
    .trade-quotes-content{
      display: unset;
      width: 100%;
      .trade-parameter{
        width: 100%;
        border: none;
      }
    }
  }

  .trade-contains .trade-quotes-content .k-line-content{
      width: 100%;
      height: unset;
      padding: 10px;
      color: #ffffff;
      border-radius: 18px;
      border:none;
  }

  .trade-contains .share-content{
    width: 100%;
  }

  .trade-contains .trade-quotes-content .trade-parameter{
    padding: 10px;
  }
}
</style>